import BaseInstance from "../base.instance";
import {
  SIMPEG_PENGAJUAN_LIST_PENDING,
  SIMPEG_PENGAJUAN_DETAIL_PENDING,
  SIMPEG_PENGAJUAN_APPROVAL_MUTASI
} from "../constants";

const SubmissionService = {
  getList(data) {
    return BaseInstance.post(SIMPEG_PENGAJUAN_LIST_PENDING, data);
  },
  getDetail(data) {
    return BaseInstance.post(SIMPEG_PENGAJUAN_DETAIL_PENDING, data);
  },
  approve(data) {
    return BaseInstance.post(SIMPEG_PENGAJUAN_APPROVAL_MUTASI, data);
  }
};

export default SubmissionService;
